import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";

export const query = graphql`
    {
        allSanityDatenschutz {
    edges {
      node {
        _rawBody
      }
    }
  }
        }
    `;

const Datenschutz = ({data}) => {

    const daten = data.allSanityDatenschutz.edges[0].node;

    return (
        <Layout newsletter={false} page={"datenschutz"}>
            <SEO title={"Datenschutz"}/>
            <div className="basepage__outer">
                <div className="basepage__inner">
                    <h4 className={"uppercase"}>Datenschutz</h4>
                    <MyPortableText value={daten._rawBody}></MyPortableText>
                </div>
            </div>
        </Layout>
    );
}

export default Datenschutz;
